import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import styled from 'styled-components'

const SearchResultItemContainer = styled.rect`
  fill: #f2f2f2;
`
const SearchResultItemMainTitle = styled.text`
  font-size: 12px;
  fill: #1a0dab;
  font-weight: 500;
`
const SearchResultItemURL = styled.text`
fill: #006621;
font-size: 9px;
`
const SearchResultItemDescription = styled.text`
font-size: 9px;
fill: #777;
`



const DynaSearchContainer = styled.div`
  .cls-1 {
    fill: #fff;
  }
  .cls-2 {
    fill: #dadada;
  }
  .cls-3 {
    fill: #ececec;
  }
  .cls-4 {
    fill: #ff645f;
  }
  .cls-5 {
    fill: #f6be4f;
  }
  .cls-6 {
    fill: #63c555;
  }
  .cls-7 {
    fill: #0874d9;
  }
  .cls-8 {
    isolation: isolate;
    font-size: 11px;
    font-weight: 600;
  }
  
  .cls-31 {
    fill: none;
    stroke: #006621;
    stroke-miterlimit: 10;
    stroke-width: 0.55px;
  }
  .cls-32 {
    font-size: 8px;
  }
  .cls-34 {
    fill: #bfbfbf;
  }

  max-width: 800px;
`
const DynaSearch = ({ search, listings }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 587.79 430.63"
  >
    <title>dynasearch</title>
    <rect className="cls-1" y="39.34" width="587.79" height="391.3" />
    <rect className="cls-2" y="39.34" width="587.79" height="78.5" />
    <rect className="cls-2" y="39.3" width="587.79" height="78.5" />
    <path
      className="cls-3"
      d="M569.65,0H18.15A18.2,18.2,0,0,0,0,18.14V76.45H587.79V18.14A18.19,18.19,0,0,0,569.65,0Z"
      transform="translate(0 -0.04)"
    />
    <circle className="cls-4" cx="29.44" cy="37.6" r="9.06" />
    <circle className="cls-5" cx="58.44" cy="37.6" r="9.06" />
    <circle className="cls-6" cx="87.45" cy="37.6" r="9.06" />
    <path
      className="cls-7"
      d="M306.32,83.25h26.84a5,5,0,0,1,5,5v15.59a5,5,0,0,1-5,5H306.32V83.25Z"
      transform="translate(0 -0.04)"
    />
    <path
      className="cls-1"
      d="M306.29,108.84H54.38a5,5,0,0,1-5-5V88.25a5,5,0,0,1,5-5H306.29v25.59Z"
      transform="translate(0 -0.04)"
    />
    <text className="cls-8" transform="translate(63 99.72)">
      {search}
    </text>
    
    <SearchResultItemContainer
      x="46.81"
      y="137.88"
      width="387.94"
      height="83.4"
      rx="3"
    />
    <SearchResultItemMainTitle transform="translate(63 162.46)">
      Website By WebPerfex
    </SearchResultItemMainTitle>
    <SearchResultItemURL transform="translate(63 177)">
      https://www.webperfex.com
    </SearchResultItemURL>
    <SearchResultItemDescription transform="translate(63 189.36)">
    Hire the Best Landscaping Companies in Antelope, CA on HomeAdvisor. We
    </SearchResultItemDescription>
    <SearchResultItemDescription transform="translate(63 201.36)">
    Have 723 Homeowner Reviews of Top Antelope Landscaping Companies.
    </SearchResultItemDescription>

    <SearchResultItemContainer
      x="45.81"
      y="228.88"
      width="387.94"
      height="83.4"
      rx="3"
    />

<SearchResultItemMainTitle transform="translate(63 253)">
      Landscaping Sacramento Near Me
    </SearchResultItemMainTitle>
    <SearchResultItemURL transform="translate(89 268)">
      https://www.webperfex.com
    </SearchResultItemURL>
    <SearchResultItemDescription transform="translate(63 280)">
    Hire the Best Landscaping Companies in Antelope, CA on HomeAdvisor. We
    </SearchResultItemDescription>
    <SearchResultItemDescription transform="translate(63 291)">
    Have 723 Homeowner Reviews of Top Antelope Landscaping Companies.
    </SearchResultItemDescription>





    <rect
      className="cls-31"
      x="62.93"
      y="260.3"
      width="22.92"
      height="9.32"
      rx="2"
    />
    <text className="cls-32" transform="translate(68.67 267.39)">
      <tspan className="cls-33">Ad</tspan>
    </text>

    <SearchResultItemContainer
      x="45.81"
      y="323.99"
      width="387.94"
      height="83.4"
      rx="3"
    />
    <rect
      className="cls-2"
      x="61.3"
      y="339.28"
      width="208.95"
      height="10.39"
      rx="3"
    />
    <rect
      className="cls-34"
      x="61.3"
      y="353.43"
      width="132.72"
      height="10.39"
      rx="3"
    />
    <rect
      className="cls-1"
      x="61.3"
      y="367.57"
      width="342.2"
      height="10.39"
      rx="3"
    />
    <rect
      className="cls-1"
      x="62.07"
      y="381.72"
      width="310.29"
      height="10.39"
      rx="3"
    />
  </svg>
)

const SecondPage = () => (
  <Layout>
    <SEO title="Page two" />
    <h1>Hi from the second page</h1>
    <p>Welcome to page 2</p>
    <DynaSearchContainer>
      <DynaSearch
        search="auto tint shop in rocklin"
        listings={[
          { siteTitle: 'WebPerfex', siteURL: 'www.webperfex.com' },
          {
            siteTitle: "America's Dream HomeWorks",
            siteURL: 'www.dreamhomeworks.co',
          },
        ]}
      />
    </DynaSearchContainer>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default SecondPage
